
  import { Component, Vue } from 'vue-property-decorator';
  import HipsterInput from '@/components/HipsterInput.vue';
  import HipsterSelector from '@/components/HipsterSelector.vue';
  import HipsterCheckbox from '@/components/HipsterCheckbox.vue';
  import InspiniaGridItem from '@/components/InspiniaGridItem.vue';
  import { SearchBookmakerPromotionsParams } from '@/types/search';
  import { SimpleTableRowItem, SimpleTableHeaderItem } from '@/types/search';
  import { BookmakerPromotion } from '@/types/entities';
  import SearchResultsTable from '@/components/SearchResultsTable.vue';
  import { FETCH_BOOKMAKER_PROMOTIONS } from '@/store/bookmakerPromotions';
  import moment from 'moment';

  @Component({
    components: { SearchResultsTable, HipsterCheckbox, HipsterInput, HipsterSelector, InspiniaGridItem },
    computed: {
      bookmakerPromotions() {
        return this.$store.state.bookmakerPromotions.bookmakerPromotionList.data
            .map((promotion: BookmakerPromotion): SimpleTableRowItem => ({
              to: `/bookmaker-promotions/${promotion.id}`,
              line: [
                promotion.id as any as string,
                promotion.bookmaker?.name as string,
                promotion.sport?.name as any as string,
                promotion.offer_description as any as string,
                (promotion.expiry_at) ? moment(promotion.expiry_at).format('YYYY-MM-DD HH:mm') : ''
              ]
            }));
      }
    }
  })
  export default class InsightsList extends Vue {
    public tableHeader: SimpleTableHeaderItem[] = [
      { class: 'col-lg-1', name: 'ID' },
      { class: 'col-lg-3', name: 'Bookmaker' },
      { class: 'col-lg-2', name: 'Sport' },
      { class: 'col-lg-4', name: 'Description' },
      { class: 'col-lg-2', name: 'Expiry at' }
    ];

    public filters: SearchBookmakerPromotionsParams = {
      per_page: 12,
      page: 1,
      with: ['sport', 'bookmaker']
    };

    created() {
      this.search({});
    }

    public search(filter: SearchBookmakerPromotionsParams) {
      if (!filter.page) {
        filter.page = 1;
      }

      this.$store.dispatch(FETCH_BOOKMAKER_PROMOTIONS, Object.assign(this.filters, filter));
    }
  }
